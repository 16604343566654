<template>
  <div :class="defaultClasses + customClass">
    <dt class="text-sm leading-5 font-medium text-gray-700">
      <slot name="info"></slot>
    </dt>
    <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
      <slot name="value"></slot>
    </dd>
  </div>
</template>

<script>
export default {
  name: 'DetailRow',
  props: {
    customClass: {
      default: '',
      type: String,
      required: false
    }
  },
  data: function() {
    return {
      defaultClasses:
        'bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 '
    }
  }
}
</script>
